<template>
  <DashboardAdminTemplate>
    <el-row>
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 12, offset: 6 }"
      >
        <div class="mg-t-3">
          <a class="text-grey-light font-18 mg-t-3" @click="$router.go(-1)"
            ><i class="el-icon-arrow-left"></i> ย้อนกลับ</a
          >
        </div>
        <div class="is-flex ai-center js-between pd-x-5 pd-y-6">
          <h2 class="mg-y-5">การแจ้งเตือน</h2>
          <div>
            <a class="text-grey" @click="readAll()"
              ><i class="fas fa-check"></i> ทำเครื่องหมายว่าอ่านแล้ว</a
            >
          </div>
        </div>
        <div
          class="pd-x-5 pd-t-5 cr-pointer hover-noti"
          v-for="(data, index) in notiList"
          :class="data.read == 0 ? 'bg-blue' : ''"
          :key="index"
        >
          <div
            class="is-flex js-between ai-center pd-b-5"
            style="border-bottom: 1px solid #e5e5e5"
            @click="readNoti(data)"
          >
            <div>
              <div class="box-image">
                <avatar
                  :username="data.name"
                  :rounded="false"
                  :src="`${
                    data.profileImg != 'undefined' && data.profileImg
                      ? imgUrl + data.profileImg
                      : ''
                  }`"
                  :size="40"
                >
                </avatar>
              </div>
            </div>
            <div class="pd-x-5 text-grey">
              {{ data.subject }}
              <span class="font-weight-semibold text-dark">
                {{ data.name }} {{ data.surname }}
              </span>
              {{ data.adverb }}
              <span class="font-weight-semibold text-dark">{{
                data.verb
              }}</span>
              เมื่อวันที่
              <span class="font-weight-semibold text-dark">
                {{ data.createdAt | dateTimeTh }}
              </span>
              <div>{{ data.createdAt | rangeFromNow }}</div>
            </div>
            <div style="width: 14px">
              <i class="fas fa-circle text-primary" v-if="data.read == 0"></i>
            </div>
          </div>
        </div>
        <div class="text-center pd-y-3">
          <a class="text-dark" v-if="total > limit" @click="getNotification(8)"
            >ดูเพิ่มเติม</a
          >
        </div>
      </el-col></el-row
    >
  </DashboardAdminTemplate>
</template>
<script>
import Avatar from "vue-avatar";
import { HTTP } from "@/service/axios";
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
export default {
  components: {
    Avatar,
    DashboardAdminTemplate
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      limit: 0,
      notiList: [],
      total: 0
    };
  },
  mounted() {
    this.getNotification(8);
  },
  methods: {
    readAll ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`read/all`)
        .then(result => {
          if (result.data.success) {
            this.getNotification();
          }
        })
        .catch(e => {
          console.log(`read/all ERROR ${e}`);
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    async readNoti(data) {
      try {
        HTTP.put(`noti/read/${data.id}`).catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
        let routeData = this.$router.resolve({
          name: "PrivacyUser"
        });
        window.open(routeData.href, "_blank");
        // this.$router.push("/privacy/user").catch(() => {});
      } catch {
        console.log("readNoti ERROR");
      }
    },
    async getNotification(val = 0) {
      try {
        this.limit = this.limit + val;
        let obj = {
          limit: this.limit,
          offset: 0
        };
        let res = await HTTP.post(`noti/consent`, obj).catch(e => {
          console.log(`search/consent ERROR ${e}`);
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
        if (res.data.success) {
          this.notiList = res.data.obj;
          this.total = res.data.total;
        }
      } catch {
        console.log("Get Notification ERROR");
      }
    }
  }
};
</script>